<template>
  <b-field
    :label="label"
    :type="type"
    :message="message"
  >
     <b-dropdown
      ref="dropdown"
      v-if="withDataset"
      :expanded="withVarname===false"
      :scrollable="true"
      :max-height="200"
      @mouseout.native="emitOut"
      @mouseover.native="emitOver"
      placeholder="Dataset"
      @input="option => changeDataset(option)"
    >
      <template #trigger>
        <b-button
          :expanded="withVarname===false"
          :label="dropdownLabel"
          :type="inType"
          icon-pack="icomoon"
          :icon-left="icon"
          icon-right="arrow-down" />
      </template>
      <b-dropdown-item
        v-for="(dataset, index) in datasets"
        :key="index"
        :value="dataset" aria-role="listitem">
        <div class="media">
          <b-icon class="media-left" pack="icomoon" :icon="icon"></b-icon>
          <div class="media-content">
            <h3><strong>{{dataset.user_name}}</strong></h3>
            <small>{{dataset.meta.ncol}} variables</small>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
    <b-taginput
      v-model="innerVarname"
      v-if="withVarname"
      :data="filteredVarnames"
      autocomplete
      expanded
      rounded
      field="name"
      :allow-new="false"
      :disabled="disabled"
      :maxtags="maxtags"
      :has-counter="maxtags>1"
      :placeholder="placeHolder"
      :open-on-focus="innerVarname.length<maxtags"
      @focus="getFilteredVarnames('')"
      @typing="option =>getFilteredVarnames(option)"
      @input="selectVariables()"
      @mouseout.native="emitOut"
      @mouseover.native="emitOver"
    >
      <template v-if="dataset !== null" #empty>
          No variables matched
      </template>
      <template v-else #empty>Select first a dataset</template>
    </b-taginput>
  </b-field>
</template>

<script>
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useUtilsStore } from '@/stores/utils'

export default {
  name: 'DatasetSelectTag',
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    vartype: {
      type: String,
      default: null
    },
    uniquevals: {
      type: Number,
      default: null
    },
    dataset: {
      type: String,
      default: null
    },
    varname: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withDataset: {
      type: Boolean,
      default: true
    },
    withVarname: {
      type: Boolean,
      default: true
    },
    type: {
      type: Object,
      default: null
    },
    message: {
      type: Array,
      default: null
    },
    minNumericVariable: {
      type: Number,
      default: 0
    },
    minSample: {
      type: Number,
      default: 0
    },
    maxtags: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      filteredVarnames: [],
      selecteDataset: null,
      innerVarname: [],
      listenAtInput: true
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const utilsStore = useUtilsStore()
    return { workspaceStore, utilsStore }
  },
  mounted () {
    if (this.dataset !== '' && this.dataset !== null) {
      this.selecteDataset = this.workspaceStore.datasets.filter(obj => obj.object_name === this.dataset)[0]
      this.updateListVarname(this.dataset)
    }
  },
  watch: {
    dataset: function () {
      this.selecteDataset = this.workspaceStore.datasets.filter(obj => obj.object_name === this.dataset)[0]
      this.updateListVarname(this.dataset)
    }
  },
  computed: {
    placeHolder: function () {
      if (this.innerVarname.length < this.maxtags) {
        return 'Select a variable'
      } else {
        return ''
      }
    },
    datasets: function () {
      if (this.vartype === 'quanti') {
        return this.workspaceStore.datasets.filter(obj => obj.meta.nrow >= this.minSample && obj.meta.nbnum >= this.minNumericVariable && obj.meta.nbnum > 0)
      } else if (this.vartype === 'quali') {
        return this.workspaceStore.datasets.filter(obj => obj.meta.nrow >= this.minSample && obj.meta.nbnum >= this.minNumericVariable && obj.meta.nbcat > 0)
      } else {
        return this.workspaceStore.datasets.filter(obj => obj.meta.nrow >= this.minSample && obj.meta.nbnum >= this.minNumericVariable)
      }
    },
    allVarnames: function () {
      return this.utilsStore.allVarnames
    },
    dropdownLabel: function () {
      let label = 'Dataset'
      if (this.selecteDataset) {
        label = this.selecteDataset.user_name
        let width = parseInt(this.$refs.dropdown.$el.clientWidth / 14)
        if (label.length > width && width !== 0) {
          label = label.substring(0, width) + '...'
        }
      }
      return label
    },
    inType: function () {
      let type = this.type
      type['is-primary is-light'] = true
      if (this.type['is-danger']) {
        type['is-primary is-light'] = false
      }
      return type
    }
  },
  methods: {
    emitOver () {
      this.$emit('mouseover')
    },
    emitOut () {
      this.$emit('mouseout')
    },
    changeDataset (dataset) {
      this.$emit('update:dataset', dataset.object_name)
      this.selecteDataset = dataset
      this.updateListVarname(dataset.object_name)
    },
    updateListVarname (datasetName) {
      this.innerVarname = []
      this.selectVariables()
      if (!(datasetName in this.allVarnames) && this.withVarname && datasetName !== '') {
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        this.utilsStore.getVarnames(datasetName)
          .then(() => {
            loadingComponent.close()
          })
          .catch(error => {
            this.error = error
            loadingComponent.close()
          })
      }
    },
    selectVariables () {
      this.$emit('update:varname', this.innerVarname.map(el => el.name))
      this.$emit('select', this.innerVarname.map(el => el.name))
    },
    getFilteredVarnames (value) {
      if (this.dataset in this.allVarnames) {
        this.filteredVarnames = this.allVarnames[this.dataset].filter((option) => {
          let toReturn = true
          if (this.vartype === 'quanti') {
            toReturn = option.numeric
          } else if (this.vartype === 'quali') {
            toReturn = !option.numeric
          }
          if (toReturn && this.uniquevals !== null) {
            toReturn = option.uniquevals <= this.uniquevals
          }
          if (toReturn && value !== '') {
            toReturn = option.name
              .toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0
          }
          return toReturn
        })
      }
    }
  }
}
</script>

<style scoped>
  .button.is-primary.is-light {
    background-color: #DEF4FD;
    color: #1896E1;
  }
  a.dropdown-item.is-active, .dropdown .dropdown-menu .has-link a.is-active, button.dropdown-item.is-active {
    background-color: #f8f6f6;
    color: #000000;
  }
</style>
